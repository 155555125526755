<template>
    <div>
        <ion-input type="password" :placeholder="$t('home.signup.details.password.placeholder')" clearInput
            class="ion-margin-top ion-margin-bottom custom" v-model="password"></ion-input>
        <ion-input type="password" :placeholder="$t('home.signup.details.password.verify')" clearInput
            class="ion-margin-top ion-margin-bottom custom" v-model="verifyPassword"></ion-input>
        <ion-progress-bar :value="passwordStrength" :class="progressBarClass"></ion-progress-bar>

        <sub class="password-criteria">
            <ion-text class="ion-padding-bottom custom">
                {{ $t('home.signup.details.password.password_requirements') }}
            </ion-text>
        </sub>
    </div>
</template>

<script>
export default {
    data() {
        return {
            password: '',
            verifyPassword: ''
        }
    },
    computed: {
        isPasswordLengthValid() {
            return this.password.length >= 8;
        },
        isPasswordContainsNumber() {
            return /\d/.test(this.password);
        },
        isPasswordContainsUppercase() {
            return /[A-Z]/.test(this.password);
        },
        isPasswordStrong() {
            return this.password.length > 10 && (this.password.match(/\d/g) || []).length >= 2;
        },
        doPasswordsMatch() {
            if (this.verifyPassword === '') return false;
            return this.password === this.verifyPassword;
        },
        passwordStrength() {
            if (this.isPasswordStrong && this.doPasswordsMatch) {
                return 1;
            } else if (this.isPasswordLengthValid && this.isPasswordContainsNumber && this.isPasswordContainsUppercase && this.doPasswordsMatch) {
                return 0.5;
            } else if (this.isPasswordLengthValid && this.isPasswordContainsNumber) {
                return 0.33;
            } else if (this.isPasswordLengthValid) {
                return 0.25;
            } else {
                return 0;
            }
        },
        passwordPassed() {
            return this.passwordStrength >= 0.49;
        },
        progressBarClass() {
            if (this.passwordStrength === 1) {
                return 'progress-bar-strong';
            } else if (this.passwordStrength >= 0.5) {
                return 'progress-bar-medium';
            } else {
                return 'progress-bar-weak';
            }
        }
    },
    watch: {
        passwordStrength(newVal, oldVal) {
            this.$emit('password-validation', this.passwordPassed);
        },
        password(newVal) {
            this.$emit('update:password', newVal);
        },
        verifyPassword(newVal) {
            this.$emit('update:verifyPassword', newVal);
        }
    },
}
</script>

<style scoped>
.progress-bar-strong {
    --ion-color-primary: green;
}

.progress-bar-medium {
    --ion-color-primary: orange;
}

.progress-bar-weak {
    --ion-color-primary: red;
}
</style>
